import React, { Fragment } from "react"
import Layout from "../components/layout/layout"
import SplashHeader from "../components/splashHeader/splashHeader"
import Footer from "../components/footer/footer"
import Accordion from "../components/accordion/accordion"
import welcome from "../components/accordionContentItems/welcome/welcome"
import howToFindHuts from "../components/accordionContentItems/howToFindHuts/howToFindHuts"
import travelTips from "../components/accordionContentItems/travelTips/travelTips"
import credits from "../components/accordionContentItems/credits/credits"
import { GeolocationContext } from "../AppState"
// import Instagram from "../components/accordionContentItems/socialMediaEmbed/instagram"
// import twitter from "../components/accordionContentItems/socialMediaEmbed/twitter"
import "../assets/styles/styles.scss"

const accordionContent = [
  {
    title: "Welcome",
    inner: welcome,
    backgroundColour: "#d2dce1",
  },
  {
    title: "How To Find Huts",
    inner: howToFindHuts,
    backgroundColour: "#f7e2a0",
  },
  {
    title: "Plan A Day Trip",
    inner: travelTips,
    backgroundColour: "#e8bf9d",
  },
  {
    title: "Credits",
    inner: credits,
    backgroundColour: "#edb7c0",
  },
]

const IndexPage = () => (
  <Layout title="Home">
    <SplashHeader />
    <Accordion noSpace contents={accordionContent} />

    <div className="u-text-center u-mb-l u-mt-l">
      <GeolocationContext.Consumer>
        {value => {
          return (
            <Fragment>
              <button onClick={value.activate} className="pointy-button">
                Give me directions
              </button>
            </Fragment>
          )
        }}
      </GeolocationContext.Consumer>
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
