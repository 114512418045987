import React from "react"
import { GeolocationContext } from "../../AppState"
import urbanHutClub from "../../assets/images/uhc-logo-full.svg"
import homeBg from "../../assets/images/home-bg.jpg"
import nzFestivalArts from "../../assets/images/nz-festival-arts.png"

const SplashHeader = () => {
  return (
    <header className="splash-header">
      <h1 className="u-accessible-text">Urban Hut Club</h1>
      <div className="splash-header__logo-wrapper">
        <img className="splash-header__bg" src={homeBg} alt="" />
        <img className="splash-header__uhc-logo" src={urbanHutClub} alt="" />
      </div>
      <div className="u-side-pad u-top-pad">
        <p className="splash-header__intro">
          Ready to discover where art and nature play together?
        </p>
        <p className="heading--small spash-header__subtitle">
          Huts open for Summer 2020/21
        </p>
        <div className="splash-header__video-container" aria-hidden>
          <iframe
            className="splash-header__video"
            width="100%"
            title="Project intro video"
            src="https://www.youtube-nocookie.com/embed/1RMjocGLRiU"
            allow="autoplay; encrypted-media; picture-in-picture"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <GeolocationContext.Consumer>
          {value => {
            return (
              <div className="u-text-center">
                <button
                  onClick={value.activate}
                  className="splash-header__call-to-action pointy-button"
                >
                  Give me directions
                </button>
              </div>
            )
          }}
        </GeolocationContext.Consumer>
        <div className="u-text-center">
          <a
            target="_blank"
            className="splash-header__nzfest-logo"
            rel="noopener noreferrer"
            href="https://www.festival.nz/events/all/urban-hut-club/"
          >
            <img
              className="credits__image"
              src={nzFestivalArts}
              alt="New Zealand Festival of the Arts"
            />
          </a>
        </div>
      </div>
    </header>
  )
}
export default SplashHeader
