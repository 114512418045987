import React, { Fragment } from "react"
import welcomeImg from "../../../assets/images/welcome.jpg"
import writersImg from "../../../assets/images/writers-uhc.jpg"

const welcomeContent = (
  <Fragment>
    <img className="width-full" src={welcomeImg} alt="" />
    <div className="welcome u-side-pad u-top-pad">
      <p>
        Lose yourself in the adventure of discovering hidden wild spaces and
        creations right on your doorstep with Urban Hut Club.
      </p>
      <p>
        Journey up the Kāpiti Coast, from Paekākāriki to Ōtaki, to find five
        mini huts designed and made by Kemi Niko & Co. with local communities.
        Each creation tells a different story through the salvaged materials
        they are built from and the new commissioned fiction by local writers
        found inside.
      </p>
    </div>
    <img className="width-full" src={writersImg} alt="" />
    <div className="welcome u-side-pad u-top-pad">
      <p>
        Each hut has been paired with a local writer who has been tasked with
        crafting a short story to furnish the hut with character. Enjoy this
        story in written or audio form, either in the hut or at home by visiting
        the hut, capturing the code and entering it on the hut page.
      </p>
      <p className="welcome__small-text">
        Commissioned by New Zealand Festival of Arts. Curated by Bret McKenzie.{" "}
        <a
          target="_blank"
          href="https://www.festival.nz/events/all/urban-hut-club/"
          rel="noopener noreferrer"
        >
          More info.
        </a>
      </p>
      <h3>
        Turn your hut journey into a learning opportunity with the Urban Hut
        Club teacher resources
      </h3>
      <p>
        <a
          target="_blank"
          href="https://www.festival.nz/documents/49/NZFOTA2020_UrbanHutClub_Teachers_Resource_JSlTMnl.pdf"
          rel="noopener noreferrer"
        >
          <em>Urban Hut Club</em> Teacher Resource
        </a>
      </p>
      <p>
        <a
          target="_blank"
          href="https://www.festival.nz/documents/38/NZFOTA2020_UrbanHutClub_What_hut_will_you_build__Resource.pdf"
          rel="noopener noreferrer"
        >
          <em>Urban Hut Club - What hut will you build?</em> Resource
        </a>
      </p>
    </div>
  </Fragment>
)

export default welcomeContent
