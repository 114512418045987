import React, { Fragment } from "react"

import kapitiLogo from "../../../assets/images/kapiti-logo.png"
import nzFestivalArts from "../../../assets/images/nz-festival-arts.png"
import nzFestivalArtsWriters from "../../../assets/images/nz-festival-arts-writers.png"
import standardGoldLogo from "../../../assets/images/creativeNZ-gold.png"
import springloadLogo from "../../../assets/images/springload-logo.svg"
import kemiNikoLogo from "../../../assets/images/knlogo.svg"
import nzctLogo from "../../../assets/images/nzctlogo.png"
import creditsImg from "../../../assets/images/credits-img.jpg"

const Credits = (
  <Fragment>
    <img className="width-full" src={creditsImg} />

    <div className="credits u-side-pad u-top-pad u-bottom-pad">
      <h3 className="heading--big">Urban Hut Club Supporters</h3>

      <div className="credits__section">
        <h4 className="heading--small">Commissioned by</h4>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.festival.nz/events/all/urban-hut-club/"
        >
          <img
            className="credits__image"
            src={nzFestivalArts}
            alt="New Zealand Festival of the Arts"
          />
        </a>
      </div>

      <div className="credits__section">
        <h4 className="heading--small">Created by</h4>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://keminiko.com/"
        >
          <img
            className="credits__image"
            src={kemiNikoLogo}
            alt="kemi Niki and co"
          />
        </a>
      </div>

      <div className="credits__section">
        <h4 className="heading--small">Partnered by</h4>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.springload.co.nz/"
        >
          <img
            className="credits__image"
            src={springloadLogo}
            alt="Springload"
          />
        </a>
      </div>

      <div className="credits__section">
        <h4 className="heading--small">Thanks to</h4>
        <div className="credits__image-grid">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.nzct.org.nz/"
          >
            <img className="" src={nzctLogo} alt="NZCT" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.creativenz.govt.nz/"
          >
            <img className="" src={standardGoldLogo} alt="Creative NZ" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.kapiticoast.govt.nz/"
          >
            <img
              className=""
              src={kapitiLogo}
              alt="Kāpiti Coast District Council"
            />
          </a>
        </div>
      </div>

      <div className="credits__section">
        <h4 className="heading--small">Hut Fiction Curated by</h4>
        <img
          className="credits__image"
          src={nzFestivalArtsWriters}
          alt="New Zealand Festival of the Arts Writers"
        />
      </div>

      <div className="credits__section">
        <h4 className="heading--small">Project Curated by</h4>
        <p>Bret McKenzie</p>
      </div>

      <div className="credits__section">
        <h2 className="heading--small">Community Support by</h2>
        <ul className="credits__list">
          <li>Whareroa Guardians Trust</li>
          <li>Paekakariki Community Orchards Group</li>
          <li>Ngāti Haumia</li>
          <li>Friends of Wainui Stream</li>
          <li>Waitohu Stream and Dune Care Group</li>
          <li>Friends of Kaitawa Reserve </li>
          <li>Department Of Conservation</li>
          <li>KCDC Parks and Recreation</li>
          <li>Nga Manu Bird Reserve</li>
          <li>Helen Forrest</li>
          <li>Paekakariki.NZ</li>
          <li>Libby &amp; Tainui Hakaraia </li>
          <li>Elevate Ōtaki</li>
          <li>CCS Disability Action Wellington</li>
        </ul>
      </div>

      <div className="credits__section">
        <h2 className="heading--small">Materials Donated by</h2>
        <ul className="credits__list">
          <li>Steve Cosgrove</li>
          <li>Andzrej Suchanski</li>
          <li>Kirk Neilson</li>
          <li>Weka Stitch </li>
          <li>Carolyn Smith</li>
          <li>Mandy and Robin Reid</li>
          <li>Renown Builders</li>
          <li>Mark Amery</li>
          <li>Peter McKenzie</li>
          <li>Lily Carter and family</li>
          <li>Te Horo Beach</li>
          <li>Sally Frater</li>
          <li>Sarah Grandison</li>
          <li>Linda Hill</li>
          <li>Penny and Ian Redwood</li>
          <li>Dulux</li>
          <li>Nicole and Jana Patana</li>
          <li>Joshua Harris</li>
          <li>Nico Borron</li>
          <li>Linda Hill</li>
          <li>Penny and Ian Redwood</li>
          <li>Human Dynamo Workshop</li>
          <li>New Zealand Festival Workshop</li>
        </ul>
      </div>

      <div className="credits__section">
        <h2 className="heading--small">Special thanks to</h2>
        <ul className="credits__list">
          <li>Jordan / Leyden family</li>
          <li>Rob Uivel &amp; Sue Dorrington </li>
          <li>Fox River Community</li>
          <li>Anna Dean</li>
          <li>Brendon and Lotti</li>
          <li>Val and Susana</li>
          <li>Wrigley Family</li>
          <li>Davies family</li>
          <li>Emma Deakin</li>
          <li>Claire Mabey</li>
          <li>Eva Prowse</li>
          <li>And the NZ Festival team</li>
          <li>Naomi Smith</li>
          <li>Hayley Jefferies</li>
        </ul>
      </div>
    </div>
  </Fragment>
)

export default Credits
