import React, { Fragment } from "react"
import img from "../../../assets/images/home-howto.jpg"
import { GeolocationContext } from "../../../AppState"
import { Link } from "gatsby"

const howToFindHuts = (
  <Fragment>
    <img className="width-full" src={img} alt="" />

    <div className="how-to-find-huts u-side-pad u-top-pad">
      <p>
        You’ve come to the right place! Here you’ll find written and visual
        clues to lead you to all of the huts.
      </p>
      <p>
        Click on a hut icon from{" "}
        <GeolocationContext.Consumer>
          {value => {
            return (
              <button onClick={value.activate} className="link-button">
                the map
              </button>
            )
          }}
        </GeolocationContext.Consumer>{" "}
        to visit the hut page. Read the directions and safety notes before you
        set out (this is a good time to hit ‘Download for Offline Use’ if you
        need to). When you are ready, take the train, bike or car to the huts
        ‘Start Location’.
      </p>
      <p>
        Once there, you’ll see the Urban Hut Club sign (see example above). From
        here follow the app directions, or refer to your downloaded PDF version,
        until you see the hut.
      </p>

      <h3 className="heading--small">
        Once you’ve found the hut, look for its unique code
      </h3>

      <p>
        Hidden inside all huts is a code that you can enter into the ‘Found it’
        box on the hut page. This will unlock the hut fiction, in written and
        audio formats.
      </p>
      <p>
        You have now ‘digitally unlocked’ this hut! Return anytime to the
        unlocked hut page to read the story at your leisure.
      </p>
      <h3 className="heading--small">
        Save your progress > save the app to your homescreen
      </h3>
      <p>
        <strong>Android</strong>: Click your browser menu button, select ‘add
        page to’ and then choose ‘homescreen’
      </p>
      <p>
        <strong>Apple</strong>: Click the menu button and select ‘add to
        homescreen’
      </p>

      <GeolocationContext.Consumer>
        {value => {
          return (
            <div className="u-text-center">
              <button
                onClick={value.activate}
                className="splash-header__call-to-action pointy-button"
              >
                Go to map
              </button>
            </div>
          )
        }}
      </GeolocationContext.Consumer>
    </div>
  </Fragment>
)

export default howToFindHuts
