import React from "react"
import { Link } from "gatsby"

const travelTips = (
  <div id="one" className="travel-tips u-top-pad">
    <div className="u-side-pad">
      <h3 className="heading--small">Take the train</h3>
      <p>
        We think trains are great! There are regular train journeys along the
        Kāpiti line that will get you to all the Urban Huts, except Ōtaki. If
        you are taking it easy and just visiting one of the huts a train journey
        is perfect, it will just increase your walking distance, see a hut page
        to find out by how much. Otherwise why not. . .
      </p>
    </div>
    <div className="travel-tips__section u-side-pad u-top-pad">
      <h3 className="travel-tips__heading heading--small">Bring your bike</h3>
      <p className="travel-tips__p">
        Trains and bikes go great together! With their powers combined you can
        visit 2 – 3 of the Urban Huts in one day. Metlink have some info on{" "}
        <a
          href="https://www.metlink.org.nz/getting-around/using-a-bicycle-on-public-transport/"
          target="_blank"
          rel="noopener noreferrer"
        >
          how to take your bike on public transport
        </a>
        .
      </p>
    </div>
    <div className="u-side-pad">
      <p>Here are some day trip ideas you might try:</p>
      <h3 className="heading--small">Paekākāriki & Whareroa Huts</h3>
      <p>
        Take the train to Paekākāriki station. From here you can bike to
        Paekākāriki Hut (through the village streets) or Whareroa Hut (via the{" "}
        <a
          href="http://paekakariki.nz/listings/te-ara-o-whareroa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Te Ara o Whareroa bike track
        </a>{" "}
        through Queen Elizabeth park). Pack a picnic, clear your schedule and do
        both in one day! Or take it easy and choose one.
      </p>
      <h3 className="heading--small">Paekākāriki, Kaitawa & Waikanae Huts</h3>
      <p>
        All of these huts are within 5-10 mins bike ride from train stations.
        Get a{" "}
        <a
          href="https://www.metlink.org.nz/tickets-and-fares/train-tickets/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Metlink Explorer
        </a>{" "}
        day ticket (zones 1-10 $20) and hop on and off, biking up to each hut as
        you go.
      </p>
      <h3 className="heading--small">Mountain Biking Day</h3>
      <p>
        Whareroa Farm has heaps of great mountain biking tracks as well as the
        biggest of the Urban Huts. Check out{" "}
        <a
          href="https://drive.google.com/open?id=1Gokey9tbVS4RKnhzwiFAD0JznFMDvxHL"
          target="_blank"
          rel="noopener noreferrer"
        >
          this map
        </a>{" "}
        and the <Link to="huts/hut-2">Whareroa Hut page</Link> to plan your
        route whether you get there by train (via Paekākāriki station) or by
        car.
      </p>
      <p>
        <a
          href="https://drive.google.com/open?id=1Gokey9tbVS4RKnhzwiFAD0JznFMDvxHL"
          target="_blank"
          rel="noopener noreferrer"
        >
          Urban Hut Club Access and Inclusion information
        </a>
      </p>
    </div>
  </div>
)

export default travelTips
